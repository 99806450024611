import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.layout.fontBody};
`;

export const Route = styled(Link)`
  color: ${(props) => props.theme.secondary};
  text-decoration: none;
  transition: 750ms;

  &:hover {
    color: ${(props) => props.theme.variants.accent[10].hex};
    transition: 750ms;
  }

  &::after {
    content: "/";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
`;
