import Chart from "react-apexcharts";
// Relative Imports
import { Container } from "./styles";
import { H5 } from "../../../constants/type.js";

const BarChart = () => {
  const series = [
    {
      name: "Height in feet",
      data: [2722, 2080, 2063, 1972, 1516],
    },
  ];
  const options = {
    chart: {
      id: "simple-bar",
    },
    xaxis: {
      categories: [
        "Burj Khalifa",
        "Tokyo Sky Tree",
        "KVLY-TV mast",
        "Abraj Al-Bait Towers",
        "Bren Tower",
      ],
    },
  };
  return (
    <Container>
      <H5 mb="20px">Bar Chart</H5>
      <Chart options={options} type="bar" series={series} />
    </Container>
  );
};

export default BarChart;
