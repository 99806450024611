import styled from "styled-components";
import media from "../../../constants/media.js";

export const Container = styled.div`
  height: 100vh;
  min-width: 280px;
  background: ${(props) => props.theme.background};

  ${media.md`
    height: 80px;
    width: 100%;
    bottom: 0px;
    position: fixed;
  `};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Image = styled.div`
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 8px;
  background: ${(props) => props.theme.foreground};
  margin-right: 16px;
  border: 1px solid ${(props) => props.theme.border};
`;

export const Profile = styled.div`
  padding: 20px;
  display: flex;
  height: auto;

  ${media.md`
    display: none;
  `};

  &:hover {
    cursor: pointer;
  }
`;
