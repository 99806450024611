import { useState } from "react";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/_modals/redeemModal";
import Header from "../../components/_layout/header";
import Task from "../../components/_overview/task";

const Profile = () => {
  const [show, showModal] = useState(false);

  const submitForm = () => {
    console.log("submitForm");
  };

  return (
    <Responsive>
      {show && <Modal onClick={() => showModal(false)} />}
      <Aside />
      <Page title="Support">
        <Header
          title="Support Tickets"
          description="Review the most recent support tickets"
        />
        <Grid rows="1">
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
        </Grid>
      </Page>
    </Responsive>
  );
};

export default Profile;
