import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.foreground};
  border-radius: ${(props) => props.theme.layout.default.radius};
  border-bottom: none;
`;

export const Row = styled(Link)`
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;

  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.hover};
  }

  &:last-child {
    border-radius: 0px 0px ${(props) => props.theme.layout.default.radius}
      ${(props) => props.theme.layout.default.radius};
  }
`;

export const Avatar = styled.div`
  width: 40px;
  margin-right: 16px;
  aspect-ratio: 1 / 1;
  background: ${(props) => props.theme.variants.grey[10].hex};
  border-radius: ${(props) => props.theme.layout.default.radius};
`;

export const Blank = styled.div`
  width: 40px;
  margin-right: 16px;
  height: 1px;
  background: ${(props) => props.theme.foreground};
`;

export const Name = styled.div``;

export const Header = styled.div`
  height: 36px;
  display: flex;
`;

export const Cell = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
