import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 28px;
  width: 28px;
  margin-right: 20px;

  ${media.sm`
    margin-right: 0px;
    margin-bottom: 4px;
  `};
`;

const Quest = ({ viewBox, primary, secondary }) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M37.7155 19.4185L35.1297 22.0043H42.0087C43.1133 22.0043 44.0087 22.8998 44.0087 24.0043C44.0087 25.1089 43.1133 26.0043 42.0087 26.0043H35.1297L37.7155 28.5901C38.4966 29.3712 38.4966 30.6375 37.7155 31.4185C36.9345 32.1996 35.6681 32.1996 34.8871 31.4185C31.8191 28.3558 30.2795 26.8188 30.2685 26.8075C29.8708 26.3979 29.2301 25.7459 28.3463 24.8513C27.8833 24.3826 27.8847 23.6283 28.3496 23.1613C29.2326 22.2743 29.8756 21.622 30.2784 21.2045L34.8871 16.5901C35.6681 15.8091 36.9345 15.8091 37.7155 16.5901C38.4966 17.3712 38.4966 18.6375 37.7155 19.4185Z"
      />
      <path
        fill={theme.icon}
        d="M28.5901 10.2932C29.3712 9.51212 30.6375 9.51212 31.4186 10.2932C32.1996 11.0742 32.1996 12.3405 31.4186 13.1216L26.8041 17.7303C26.3867 18.1331 25.7344 18.7761 24.8473 19.6591C24.3804 20.124 23.6261 20.1254 23.1574 19.6623C22.2628 18.7786 21.6108 18.1378 21.2012 17.7401C21.1899 17.7292 19.6528 16.1896 16.5901 13.1216C15.8091 12.3405 15.8091 11.0742 16.5901 10.2932C17.3712 9.51212 18.6375 9.51212 19.4186 10.2932L22.0043 12.879V6C22.0043 4.89543 22.8998 4 24.0043 4C25.1089 4 26.0043 4.89543 26.0043 6V12.879L28.5901 10.2932ZM28.5901 37.7155L26.0043 35.1297V42.0087C26.0043 43.1133 25.1089 44.0087 24.0043 44.0087C22.8998 44.0087 22.0043 43.1133 22.0043 42.0087V35.1297L19.4186 37.7155C18.6375 38.4966 17.3712 38.4966 16.5901 37.7155C15.8091 36.9345 15.8091 35.6681 16.5901 34.8871C19.6528 31.8191 21.1899 30.2795 21.2012 30.2685C21.6108 29.8708 22.2628 29.2301 23.1574 28.3463C23.6261 27.8833 24.3804 27.8847 24.8473 28.3496C25.7344 29.2326 26.3867 29.8756 26.8041 30.2784L31.4186 34.8871C32.1996 35.6681 32.1996 36.9345 31.4186 37.7155C30.6375 38.4966 29.3712 38.4966 28.5901 37.7155ZM10.2932 16.5901C9.51212 17.3712 9.51212 18.6375 10.2932 19.4186L12.879 22.0043H6C4.89543 22.0043 4 22.8998 4 24.0043C4 25.1089 4.89543 26.0043 6 26.0043H12.879L10.2932 28.5901C9.51212 29.3712 9.51212 30.6375 10.2932 31.4186C11.0742 32.1996 12.3405 32.1996 13.1216 31.4186C16.1896 28.3559 17.7292 26.8188 17.7401 26.8075C18.1378 26.3979 18.7786 25.7459 19.6623 24.8513C20.1254 24.3826 20.124 23.6283 19.6591 23.1613C18.7761 22.2743 18.1331 21.622 17.7303 21.2045L13.1216 16.5901C12.3405 15.8091 11.0742 15.8091 10.2932 16.5901Z"
      />
    </Svg>
  );
};

export default Quest;
