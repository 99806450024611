import Chart from "react-apexcharts";
// Relative Imports
import { Container } from "./styles";
import { H5 } from "../../../constants/type.js";

const RadialChart = () => {
  const series = [70]; //70 percent
  const options = {
    labels: ["Progress"], //label of this diagram
  };
  return (
    <Container>
      <H5 mb="20px">Radial Bar</H5>
      <Chart options={options} type="radialBar" series={series} />
    </Container>
  );
};

export default RadialChart;
