import styled, { keyframes } from "styled-components";

const scale = keyframes`
  0% { -webkit-transform: translateY(-20px)  }
  50% { -webkit-transform: translateY(20px)   }
  100% { -webkit-transform: translateY(0px)  }
`;

export const Container = styled.div`
  height: 420px;
  width: 320px;
  background: pink;
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 10000;
  animation: ${scale} 500ms ease-out;
  box-shadow: 6px 10px 8px 8px rgba(194, 211, 226, 0.15);
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
  border-radius: ${(props) => props.theme.layout.default.radius};
`;

export const Header = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const Body = styled.div`
  padding: 24px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;
