import { useState } from "react";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/_modals/redeemModal";
import Header from "../../components/_layout/header/index.js";
import LineChart from "../../components/_charts/line";
import BarChart from "../../components/_charts/bar";
import PieChart from "../../components/_charts/pie";
import RadialChart from "../../components/_charts/radial";

const Announcements = () => {
  const [show, showModal] = useState(false);

  return (
    <Responsive>
      {show && <Modal onClick={() => showModal(false)} />}
      <Aside />
      <Page title="Announcements" />
    </Responsive>
  );
};

export default Announcements;
