export const waitlist = [
  {
    public_address: "",
    firstName: "Steven",
    lastName: "Mahana",
    discordHandle: "test2#2222",
    emailAddress: "random_065@gmail.com",
    applicantType: "Developer",
    userDescription: "I love games",
    inviteCode: "123",
    inviteCodeUsed: "456",
    refCode: "ref-code-22",
  },
  {
    public_address: "",
    firstName: "Adam",
    lastName: "Walsh",
    discordHandle: "test2#2222",
    emailAddress: "random_065@gmail.com",
    applicantType: "Developer",
    userDescription: "I love games",
    inviteCode: "123",
    inviteCodeUsed: "456",
    refCode: "ref-code-22",
  },
  {
    public_address: "",
    firstName: "Sarah",
    lastName: "James",
    discordHandle: "test2#2222",
    emailAddress: "random_065@gmail.com",
    applicantType: "Developer & Gamer",
    userDescription: "I love games",
    inviteCode: "123",
    inviteCodeUsed: "456",
    refCode: "ref-code-22",
  },
  {
    public_address: "",
    firstName: "Alan",
    lastName: "Jones",
    discordHandle: "test2#2222",
    emailAddress: "random_065@gmail.com",
    applicantType: "Gamer",
    userDescription: "I love games",
    inviteCode: "123",
    inviteCodeUsed: "456",
    refCode: "ref-code-22",
  },
  {
    public_address: "",
    firstName: "Ronen",
    lastName: "Kirsh",
    discordHandle: "test2#2222",
    emailAddress: "random_065@gmail.com",
    applicantType: "Gamer",
    userDescription: "I love games",
    inviteCode: "123",
    inviteCodeUsed: "456",
    refCode: "ref-code-22",
  },
];
