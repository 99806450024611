import styled from "styled-components";
import media from "../../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  z-index: 5000;
  background: ${(props) => props.theme.ground};
  border: 1px solid ${(props) => props.theme.border};

  ${media.md`
    height: calc(100vh - 80px);
    border-radius: 0px;
  `};
`;

export const Route = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Breadcrumb = styled(Link)`
  font-family: "Inter", sans-serif;
  text-decoration: none;
  color: ${(props) => props.theme.secondary};
`;

export const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const Content = styled.div`
  padding: 20px;
  overflow: scroll;
`;

export const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  grid-gap: 20px;
  align-items: center;
`;

export const Routes = styled.div`
  display: flex;
  grid-gap: 4px;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme.secondary};
`;

export const Gallery = styled(Link)`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Notifications = styled.img`
  height: 36px;
  width: 36px;
  cursor: pointer;
`;

export const Nft = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: linear-gradient(180deg, #d800dc 0%, rgba(179, 0, 0, 0.56) 100%);
`;

export const Profile = styled(Link)`
  height: 48px;
  width: 48px;
  border-radius: 4px;
  overflow: hidden;
  background: ${(props) => props.theme.placeholder};
`;
