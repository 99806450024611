import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 28px;
  width: 28px;
  margin-right: 20px;

  ${media.sm`
    margin-right: 0px;
    margin-bottom: 4px;
  `};
`;

const Dashboard = () => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M31.0711 16.9289C29.168 15.0258 26.6343 13.999 23.9961 14C21.3606 14.001 18.8301 15.0277 16.9289 16.9289C13.0237 20.8342 13.0237 27.1658 16.9289 31.0711C18.8302 32.9724 21.3609 33.9991 23.9966 34C25.1012 34.0004 25.9963 34.8961 25.9959 36.0007C25.9955 37.1052 25.0998 38.0004 23.9952 38C20.3096 37.9988 16.7593 36.5583 14.1005 33.8995C8.63316 28.4322 8.63316 19.5678 14.1005 14.1005C16.7592 11.4418 20.3092 10.0014 23.9946 10C27.6837 9.99858 31.2382 11.4392 33.8995 14.1005C34.6805 14.8816 34.6805 16.1479 33.8995 16.9289C33.1184 17.71 31.8521 17.71 31.0711 16.9289Z"
      />
      />
      <path
        fill={theme.icon}
        d="M24 32C19.5817 32 16 28.4183 16 24C16 19.5817 19.5817 16 24 16C25.1046 16 26 16.8954 26 18C26 19.1046 25.1046 20 24 20C21.7909 20 20 21.7909 20 24C20 26.2091 21.7909 28 24 28C25.1046 28 26 28.8954 26 30C26 31.1046 25.1046 32 24 32ZM24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 25.1046 43.1046 26 42 26C40.8954 26 40 25.1046 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C25.1046 40 26 40.8954 26 42C26 43.1046 25.1046 44 24 44Z"
      />
    </Svg>
  );
};

export default Dashboard;
