import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 28px;
  width: 28px;
  margin-right: 20px;

  ${media.sm`
  margin-right: 0px;
  margin-bottom: 4px;
`};
`;

const Support = () => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M18.0123 38.3875C17.9988 38.1748 18.1746 38 18.3877 38H29.6176C29.8278 38 30.0012 38.1724 29.988 38.3822V38.3822C29.7909 41.5178 27.1853 44 24 44C20.8165 44 18.212 41.5206 18.0123 38.3875V38.3875Z"
      />
      />
      <path
        fill={theme.icon}
        d="M33.0104 20.2907C32.6964 16.5234 30.0786 13.3754 26.4949 12.3484L26.2038 12.265C24.7636 11.8523 23.2364 11.8523 21.7962 12.265L21.5051 12.3484C17.9214 13.3754 15.3036 16.5234 14.9896 20.2907L14.0139 32H33.9861L33.0104 20.2907ZM38 32H40C41.1046 32 42 32.8954 42 34C42 35.1046 41.1046 36 40 36H8C6.89543 36 6 35.1046 6 34C6 32.8954 6.89543 32 8 32H10L11.0035 19.9585C11.4633 14.4401 15.3139 9.96163 20.4032 8.50321C20.1468 8.06122 20 7.54775 20 7C20 5.34315 21.3431 4 23 4H25C26.6569 4 28 5.34315 28 7C28 7.54775 27.8532 8.06122 27.5968 8.50321C32.6861 9.96163 36.5367 14.4401 36.9965 19.9585L38 32ZM35.9561 11.2966C35.241 10.4552 35.343 9.19296 36.1839 8.47741C37.0247 7.76186 38.286 7.86394 39.001 8.70541C41.5623 11.7196 43.1346 15.4639 43.4674 19.4603L43.9985 25.839C44.0902 26.9398 43.2728 27.9065 42.1729 27.9982C41.073 28.09 40.107 27.272 40.0153 26.1712L39.4841 19.7925C39.2211 16.6342 37.98 13.6785 35.9561 11.2966ZM5.83266 27.9997C6.9326 28.0914 7.89858 27.2734 7.99024 26.1727L8.52153 19.7925C8.7843 16.637 10.0235 13.6837 12.0443 11.3029C12.7588 10.4611 12.6561 9.19892 11.8149 8.48383C10.9737 7.76874 9.71245 7.87151 8.9979 8.71337C6.4406 11.7263 4.8708 15.4675 4.53831 19.4603L4.00701 25.8405C3.91535 26.9413 4.73272 27.908 5.83266 27.9997Z"
      />
    </Svg>
  );
};

export default Support;
