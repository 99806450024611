import styled from "styled-components";

export const Container = styled.div`
  height: auto;
`;

export const Input = styled.input`
  height: auto;
  padding: 12px 16px;
  width: 420px;
  border-radius: 30px;
  font-family: "Inter", san-serif;
  color: ${(props) => props.theme.primary};
  border: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.background};
  outline: none;
  font-size: 13px;
`;

export const Results = styled.div`
  height: 600px;
  width: 450px;
  z-index: 10000;
  position: fixed;
  border-radius: 8px;
  margin-top: 8px;
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
`;
