// Relative Imports
import { Container } from "./styles";
import { H5, P } from "../../../constants/type.js";

const Header = ({ mt, mb, title, description }) => {
  return (
    <Container mb="20px" mt={mt}>
      <H5>{title}</H5>
      <P>{description}</P>
    </Container>
  );
};

export default Header;
