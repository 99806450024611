import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 48px;
  width: 48px;

  ${media.sm`
    margin-right: 0px;
    margin-bottom: 4px;
  `};
`;

const LightTheme = ({ viewBox, primary, secondary }) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M11.2721 36.7279C10.491 35.9469 10.491 34.6805 11.2721 33.8995L12.6863 32.4853C13.4673 31.7042 14.7337 31.7042 15.5147 32.4853C16.2958 33.2663 16.2958 34.5326 15.5147 35.3137L14.1005 36.7279C13.3194 37.509 12.0531 37.509 11.2721 36.7279ZM32.4853 15.5147C31.7042 14.7337 31.7042 13.4673 32.4853 12.6863L33.8995 11.2721C34.6805 10.491 35.9469 10.491 36.7279 11.2721C37.509 12.0531 37.509 13.3194 36.7279 14.1005L35.3137 15.5147C34.5326 16.2958 33.2663 16.2958 32.4853 15.5147ZM36.7279 36.7279C35.9469 37.509 34.6805 37.509 33.8995 36.7279L32.4853 35.3137C31.7042 34.5326 31.7042 33.2663 32.4853 32.4853C33.2663 31.7042 34.5326 31.7042 35.3137 32.4853L36.7279 33.8995C37.509 34.6805 37.509 35.9469 36.7279 36.7279ZM15.5147 15.5147C14.7337 16.2958 13.4673 16.2958 12.6863 15.5147L11.2721 14.1005C10.491 13.3194 10.491 12.0531 11.2721 11.2721C12.0531 10.491 13.3194 10.491 14.1005 11.2721L15.5147 12.6863C16.2958 13.4673 16.2958 14.7337 15.5147 15.5147Z"
      />
      <path
        fill={theme.icon}
        d="M31.0711 16.9289C34.9763 20.8342 34.9763 27.1658 31.0711 31.0711C27.1658 34.9763 20.8342 34.9763 16.9289 31.0711C13.0237 27.1658 13.0237 20.8342 16.9289 16.9289C20.8342 13.0237 27.1658 13.0237 31.0711 16.9289ZM28.2426 19.7574C25.8995 17.4142 22.1005 17.4142 19.7574 19.7574C17.4142 22.1005 17.4142 25.8995 19.7574 28.2426C22.1005 30.5858 25.8995 30.5858 28.2426 28.2426C30.5858 25.8995 30.5858 22.1005 28.2426 19.7574ZM42 24C42 25.1046 41.1046 26 40 26H38C36.8954 26 36 25.1046 36 24C36 22.8954 36.8954 22 38 22H40C41.1046 22 42 22.8954 42 24ZM12 24C12 25.1046 11.1046 26 10 26H8C6.89543 26 6 25.1046 6 24C6 22.8954 6.89543 22 8 22H10C11.1046 22 12 22.8954 12 24ZM24 6C25.1046 6 26 6.89543 26 8V10C26 11.1046 25.1046 12 24 12C22.8954 12 22 11.1046 22 10V8C22 6.89543 22.8954 6 24 6ZM24 36C25.1046 36 26 36.8954 26 38V40C26 41.1046 25.1046 42 24 42C22.8954 42 22 41.1046 22 40V38C22 36.8954 22.8954 36 24 36Z"
      />
    </Svg>
  );
};

export default LightTheme;
