// Relative Imports

import { Container, Route, Icon, Item, List, Auth, H6 } from "./styles";

// Icons
import Profile from "../../../assets/interface/profile.js";
import Dashboard from "../../../assets/interface/dashboard.js";
import Quest from "../../../assets/interface/quest.js";
import Governance from "../../../assets/interface/governance.js";
import Settings from "../../../assets/interface/settings.js";
import Support from "../../../assets/interface/support.js";

const Menu = () => {
  // const handleSession = () => {
  //   handleLogOut();
  //   if (user && !showAuthFlow) {
  //     window.location.assign("/");
  //   }
  // };

  // <Auth onClick={() => handleSession()}>Log Out</Auth>

  return (
    <Container>
      <List>
        <H6 ml="20px" mt="20px" mb="8px">
          General
        </H6>
        <Route to="/overview">
          <Profile />
          <Item>Overview</Item>
        </Route>

        <Route to="/insights">
          <Dashboard />
          <Item>Insights</Item>
        </Route>
        <Route to="/support">
          <Support />
          <Item>Support</Item>
        </Route>
      </List>
      <List>
        <H6 ml="20px" mt="20px" mb="8px">
          Users
        </H6>
        <Route to="/waitlist">
          <Quest />
          <Item>Waitlist</Item>
        </Route>
        <Route to="/community">
          <Governance />
          <Item>Community</Item>
        </Route>
      </List>
      <List>
        <H6 ml="20px" mt="20px" mb="8px">
          Events
        </H6>
        <Route to="/calendar">
          <Governance />
          <Item>Calendar</Item>
        </Route>
        <Route to="/announcements">
          <Governance />
          <Item>Announcements</Item>
        </Route>
      </List>
      <List>
        <H6 ml="20px" mt="20px" mb="8px">
          Account
        </H6>
        <Route to="/settings">
          <Governance />
          <Item>Settings</Item>
        </Route>
      </List>
    </Container>
  );
};

export default Menu;
