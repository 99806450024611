// Relative Imports
import { Container, Field, Label } from "./styles";

const TextArea = ({ label, placeholder, onChange, rows }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Field onChange={onChange} placeholder={placeholder} cols="60" rows="5" />
    </Container>
  );
};

export default TextArea;
