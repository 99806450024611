import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 28px;
  width: 28px;
  margin-right: 20px;

  ${media.sm`
    margin-right: 0px;
    margin-bottom: 4px;
  `};
`;

const Profile = () => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M18 26C18 24.8954 18.
        margin-bottom: 4px;8954 24 20 24H27.9998C29.1044 24 29.9998 24.8954 29.9998 26V26C29.9998 27.1046 29.1044 28 27.9998 28H20C18.8954 28 18 27.1046 18 26V26Z"
      />
      />
      <path
        fill={theme.icon}
        d="M8.00066 19.134L6.9716 19.7434C6.00605 20.2798 4.78846 19.9319 4.25205 18.9663C3.71563 18.0008 4.06351 16.7832 5.02906 16.2467L21.0862 6.75506C22.8983 5.74831 25.1017 5.74831 26.9138 6.75506L42.9709 16.2467C43.9365 16.7832 44.2844 18.0008 43.748 18.9663C43.2115 19.9319 41.994 20.2798 41.0284 19.7434L40.0001 19.1344V40.8C40.0001 41.4627 39.4628 42 38.8001 42H27.2003C26.5376 42 26.0003 41.4627 26.0003 40.8V34.4C26.0003 34.1791 25.8212 34 25.6003 34H22.4004C22.1795 34 22.0004 34.1791 22.0004 34.4V40.8C22.0004 41.4627 21.4632 42 20.8004 42H9.20063C8.5379 42 8.00066 41.4627 8.00066 40.8V19.134ZM12.0006 16.7654V37.6C12.0006 37.8209 12.1797 38 12.4006 38H17.6005C17.8214 38 18.0005 37.8209 18.0005 37.6V32C18.0005 30.8954 18.8959 30 20.0005 30H28.0003C29.1048 30 30.0003 30.8954 30.0003 32V37.6C30.0003 37.8209 30.1793 38 30.4002 38H35.6002C35.8211 38 36.0001 37.8209 36.0001 37.6V16.7658L24.9713 10.235C24.3672 9.89946 23.6328 9.89946 23.0287 10.235L12.0006 16.7654Z"
      />
    </Svg>
  );
};

export default Profile;
