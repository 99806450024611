// Relative Imports
import { Container, Column, Route, Disabled, Icon } from "./styles";
import { H5, P } from "../../../constants/type.js";

const Task = ({ route, title, description, disabled }) => {
  return (
    <Container>
      <Route to={route}>
        <Column>
          <H5>{title}</H5>
          <P mt="4px">{description}</P>
        </Column>
      </Route>
    </Container>
  );
};

export default Task;
