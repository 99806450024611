import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 28px;
  width: 28px;
  margin-right: 20px;

  ${media.sm`
    margin-right: 0px;
    margin-bottom: 4px;
  `};
`;

const Voting = ({ viewBox, primary, secondary }) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M14.5322 22.3079L11.8964 23.6432C11.6993 23.743 11.6205 23.9837 11.7203 24.1808C11.7588 24.2567 11.8205 24.3184 11.8964 24.3568L23.2769 30.1222C23.7315 30.3525 24.2685 30.3525 24.7231 30.1222L36.1036 24.3568C36.3007 24.257 36.3795 24.0163 36.2797 23.8192C36.2412 23.7433 36.1795 23.6816 36.1036 23.6432L33.4678 22.3079L37.702 20.1628L40.534 21.5976C41.8435 22.261 42.3743 23.8744 41.7195 25.2012C41.463 25.721 41.047 26.1425 40.534 26.4024L25.6937 33.9206C24.6275 34.4607 23.3725 34.4607 22.3063 33.9206L7.46601 26.4024C6.15648 25.739 5.62569 24.1256 6.28045 22.7988C6.53697 22.279 6.95297 21.8575 7.46601 21.5976L10.298 20.1628L14.5322 22.3079Z"
      />
      <path
        fill={theme.icon}
        d="M40.534 18.7281L25.6937 26.2463C24.6275 26.7864 23.3725 26.7864 22.3063 26.2463L7.46601 18.7281C6.15648 18.0647 5.62569 16.4513 6.28045 15.1245C6.53697 14.6047 6.95297 14.1832 7.46601 13.9233L22.3063 6.4051C23.3725 5.86497 24.6275 5.86497 25.6937 6.4051L40.534 13.9233C41.8435 14.5867 42.3743 16.2001 41.7195 17.5269C41.463 18.0467 41.047 18.4682 40.534 18.7281ZM14.5322 29.9822L11.8964 31.3175C11.6993 31.4173 11.6205 31.658 11.7203 31.8551C11.7588 31.931 11.8205 31.9927 11.8964 32.0311L23.2769 37.7965C23.7315 38.0268 24.2685 38.0268 24.7231 37.7965L36.1036 32.0311C36.3007 31.9313 36.3795 31.6906 36.2797 31.4935C36.2412 31.4176 36.1795 31.3559 36.1036 31.3175L33.4678 29.9822L37.702 27.8371L40.534 29.2719C41.8435 29.9353 42.3743 31.5487 41.7195 32.8755C41.463 33.3953 41.047 33.8168 40.534 34.0767L25.6937 41.5949C24.6275 42.135 23.3725 42.135 22.3063 41.5949L7.46601 34.0767C6.15648 33.4133 5.62569 31.7999 6.28045 30.4731C6.53697 29.9533 6.95297 29.5318 7.46601 29.2719L10.298 27.8371L14.5322 29.9822V29.9822ZM23.2769 10.2035L11.8964 15.9689C11.6993 16.0687 11.6205 16.3094 11.7203 16.5065C11.7588 16.5824 11.8205 16.6441 11.8964 16.6825L23.2769 22.4479C23.7315 22.6782 24.2685 22.6782 24.7231 22.4479L36.1036 16.6825C36.3007 16.5827 36.3795 16.342 36.2797 16.1449C36.2412 16.069 36.1795 16.0073 36.1036 15.9689L24.7231 10.2035C24.2685 9.97318 23.7315 9.97318 23.2769 10.2035Z"
      />
    </Svg>
  );
};

export default Voting;
