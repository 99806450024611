// Relative Imports
import { Container, Thread, Avatar, Comment } from "./styles";
import Input from "../../_forms/input/index.js";

import { H5, H6, P } from "../../../constants/type.js";

const Comments = () => {
  return (
    <Container>
      <H5>Comments</H5>
      <Thread>
        <Avatar />
        <Comment>
          <H6 mb="4px">Jun Kim</H6>
          <P>
            Come, come, you talk greasily; your lips grow foul. Hast thou never
            an eye in thy head? Thou clouted crook-pated hugger-mugger! Thou
            dissembling idle-headed pigeon-egg! Thou mangled fen-sucked
            bum-bailey!{" "}
          </P>
        </Comment>
      </Thread>
      <Thread>
        <Avatar />
        <Comment>
          <H6 mb="4px">Michael Morris</H6>
          <P>
            Come, come, you talk greasily; your lips grow foul. Hast thou never
            an eye in thy head? Thou clouted crook-pated hugger-mugger! Thou
            dissembling idle-headed pigeon-egg! Thou mangled fen-sucked
            bum-bailey!{" "}
          </P>
        </Comment>
      </Thread>
      <Input placeholder="Enter a comment" />
    </Container>
  );
};

export default Comments;
