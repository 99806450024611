// Relative Imports
import { Container, Button } from "./styles";
import { H5 } from "../../../constants/type.js";
import Cell from "../../cell";

const Vote = ({ onClick }) => {
  return (
    <Container>
      <H5 mb="12px">Details</H5>
      <Cell key={"1"} name="Registered" value="Dec 1, 2022" />
      <Cell key={"2"} name="Type" value="Developer" />
      <Cell key={"3"} name="Member" value="Undecided" />
      <Cell key={"4"} name="Invite Code" value="None" />
      <Cell key={"4"} name="Referral Code" value="None" />
      <Button onClick={onClick}>Accept User</Button>
    </Container>
  );
};

export default Vote;
