import { useState } from "react";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/_modals/redeemModal";
import Header from "../../components/_layout/header/index.js";
import Reward from "../../components/_rewards/reward";
import Level from "../../components/_overview/level/index.js";
import Task from "../../components/_overview/task/index.js";
import Single from "../../components/_grid/single/index.js";

const Profile = () => {
  const [show, showModal] = useState(false);

  const submitForm = () => {
    console.log("submitForm");
  };

  return (
    <Responsive>
      {show && <Modal onClick={() => showModal(false)} />}
      <Aside />
      <Page title="Overview">
        <Header
          title="Quick Stats"
          description="Quick glance review of the community operations"
        />
        <Grid rows="3">
          <Level route="/insights" title="42" description="NPS Score" />
          <Level
            route="/waitlist"
            title="2,371"
            description="Waitlist Registrations"
          />
          <Level
            route="/community"
            title="1,194"
            description="Community Members"
          />
        </Grid>
        <Header
          mt="20px"
          title="Support Tickets"
          description="Review the most recent support tickets"
        />
        <Grid rows="1">
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
          <Task
            route="/support"
            title="Lorem ipsum dolor"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
          />
        </Grid>
      </Page>
    </Responsive>
  );
};

export default Profile;
