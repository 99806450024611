import styled, { keyframes } from "styled-components";
import media from "../../../constants/media.js";

const scale = keyframes`
  0% { -webkit-transform: translateY(-20px)  }
  50% { -webkit-transform: translateY(20px)   }
  100% { -webkit-transform: translateY(0px)  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 10000;
  position: fixed;
  display: flex;
  justify-content: center;
  padding-top: 80px;
`;

export const Window = styled.div`
  width: 580px;
  height: max-content;
  background: white;
  border-radius: 8px;
  padding: 0px ${(props) => props.theme.layout.default.padding};
  ${(props) => props.theme.layout.default.padding};
  ${(props) => props.theme.layout.default.padding};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  animation: ${scale} 500ms ease-out;

  ${media.sm`
    max-height: 700px;
  `};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  height: 320px;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 4000;
  flex-direction: column;
  align-items: center;
  grid-gap: ${(props) => props.theme.layout.default.gap};
  height: 300px;
  margin-bottom: -320px;
`;

export const Animation = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 340px;
  z-index: 2000;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.layout.default.margin};
`;

export const Left = styled.div`
  width: 140px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${(props) => props.theme.accent};
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  border: 1px solid ${(props) => props.theme.variants.accent[10].hex};

  &:hover {
    cursor: pointer;
  }
`;

export const Right = styled.button`
  width: 140px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  font-size: 16px;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  background: ${(props) => props.theme.variants.accent[10].hex};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const Link = styled.a`
  width: 140px;
  height: 48px;
  border-radius: ${(props) => props.theme.layout.default.radius};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  background: ${(props) => props.theme.accent};

  &:hover {
    cursor: pointer;
  }
`;
