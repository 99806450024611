// Relative Imports
// https://dev.to/pavsaund/build-dynamic-breadcrumb-routes-and-child-routes-with-mathpath-in-react-router-v6-2jcf
import { useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Container,
  Header,
  Content,
  Profile,
  Notifications,
  Gallery,
  Row,
  Nft,
  Route,
  Routes,
  Cover,
} from "./styles";
import { UserTheme } from "../../../hooks/userTheme.js";
import { H4 } from "../../../constants/type.js";
import Alerts from "../../notifications/index.js";
import Notification from "../../../assets/interface/notification.js";
import Community from "../../../assets/interface/community.js";
import LightTheme from "../../../assets/interface/lightTheme.js";
import DarkTheme from "../../../assets/interface/darkTheme.js";
import nft from "../../../assets/nft/nft.png";
import Breadcrumbs from "../breadcrumb/index.js";
import Search from "../../search/index.js";

const Page = ({ title, children }) => {
  const { currentTheme, setTheme } = useContext(UserTheme);
  const [display, toggleDisplay] = useState(false);
  const location = useLocation();
  return (
    <Container>
      <Header>
        <Route>
          <H4 textTransform={"uppercase"}>{title}</H4>
          <Breadcrumbs />
        </Route>

        <Row>
          <Search />
          <Cover
            onClick={() =>
              setTheme(currentTheme === "light" ? "dark" : "light")
            }
          >
            {currentTheme === "light" ? <DarkTheme /> : <LightTheme />}
          </Cover>
        </Row>
      </Header>
      {display && <Alerts />}
      <Content>{children}</Content>
    </Container>
  );
};

export default Page;
