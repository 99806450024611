import styled from "styled-components";

export const Container = styled.div`
  height: 240px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: ${(props) => props.theme.layout.default.padding};
  border-radius: ${(props) => props.theme.layout.default.radius};
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
`;
