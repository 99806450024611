//stackoverflow.com/questions/60519123/passing-value-to-state-using-react-select
import { useTheme } from "styled-components";
import Select from "react-select";
// Relative Imports
import { Container, Labels } from "./styles";
import { Label, Error } from "../../../constants/type.js";

const Dropdown = ({ label, error, options, defaultValue, onChange }) => {
  const theme = useTheme();
  const style = {
    // Options
    option: (provided, state) => ({
      ...provided,
      height: "52px",
      background: "none",
      borderLeft: `1px solid ${theme.border}`,
      borderRight: `1px solid ${theme.border}`,
      fontFamily: `${theme.layout.fontBody}`,
      color: state.isSelected ? `${theme.primary}` : `${theme.secondary}`,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      // Select Field
      width: "auto",
      height: "52px",
      borderRadius: "4px",
      display: "flex",
      paddingLeft: "8px",
      background: `${theme.foreground}`,
      border: `1px solid ${theme.border}`,
      fontFamily: `${theme.layout.fontBody}`,
      hover: "#000000",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };
  return (
    <Container>
      <Labels>
        <Label>{label}</Label>
        <Error>{error}</Error>
      </Labels>
      <Select
        components={{
          IndicatorSeparator: () => null,
        }}
        defaultValue={defaultValue}
        onChange={onChange}
        styles={style}
        options={options}
      />
    </Container>
  );
};

export default Dropdown;
