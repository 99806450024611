import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Navigation from "../components/_layout/navigation";
import Hero from "../components/hero";
import Footer from "../components/footer";

const Home = () => {
  let navigate = useNavigate();

  return (
    <>
      <Navigation />
      <Hero title="COPS" onClick={() => window.location.assign("/overview")} />
    </>
  );
};

export default Home;
