import { useState } from "react";
// Relative Imports
import { Container, Input, Results } from "./styles";

const Search = () => {
  const [show, showResults] = useState(false);
  const [result, setFormResult] = useState("");

  return (
    <Container>
      <Input
        placeholder="Search community"
        value={result || ""}
        onChange={(e) => setFormResult(e.target.value)}
      />

      {result.length > 0 && <Results />}
    </Container>
  );
};

export default Search;
