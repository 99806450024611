// Relative Imports
import { Container, Row, Cell, Avatar, Name, Header, Blank } from "./styles";
import { P, H6 } from "../../../constants/type.js";

const Leaderboard = ({ data, to }) => {
  const renderData = () => {
    return data.map((d, i) => {
      return (
        <Row key={i} to={`/waitlist/${d.firstName}`}>
          <Cell>
            <Name>
              <P color="primary">{d.firstName + " " + d.lastName}</P>
            </Name>
          </Cell>
          <Cell>
            <P color="primary">{d.applicantType}</P>
          </Cell>
          <Cell>
            <P color="primary">{d.discordHandle}</P>
          </Cell>
          <Cell>
            <P color="primary">{d.inviteCode}</P>
          </Cell>
          <Cell>
            <P color="primary">{d.userDescription}</P>
          </Cell>
        </Row>
      );
    });
  };
  return (
    <Container>
      <Header>
        <Cell>
          <H6>Name</H6>
        </Cell>
        <Cell>
          <H6>Type</H6>
        </Cell>
        <Cell>
          <H6>Discord</H6>
        </Cell>
        <Cell>
          <H6>Invite Code</H6>
        </Cell>
        <Cell>
          <H6>Registered</H6>
        </Cell>
      </Header>
      {renderData()}
    </Container>
  );
};

export default Leaderboard;
