import styled from "styled-components";

export const Container = styled.div`
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.background};
  border-radius: ${(props) => props.theme.layout.default.radius};
`;

const Group = styled.div`
  display: flex;
`;

export const Tab = styled.button`
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.variants.grey[10].hex};

  ${({ active }) =>
    active &&
    `
  background: #EF233C;
  color: white;
`}
`;
