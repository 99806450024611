import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Point from "../../components/_forms/point";
import Input from "../../components/_forms/input";
import Dropdown from "../../components/_forms/dropdown";
import Single from "../../components/_grid/single";
import Double from "../../components/_grid/double";
import Account from "../../components/_proposals/profile";
import Header from "../../components/_layout/header";
import Splitter from "../../components/_forms/splitter";

const options = [
  { value: "", label: "0x1234....5678" },
  { value: "", label: "0x8471....ed71" },
  { value: "", label: "0xd018....9618" },
];

const Settings = () => {
  return (
    <Responsive>
      <Aside />
      <Page title="Settings">
        <Header
          title="Account Settings"
          description="Expedita velit consequatur sit cum sed beatae exercitationem qui."
        />
        <Splitter>
          <Input label="First Name" placeholder="Enter first name" />
          <Input label="Last Name" placeholder="Enter last name" />
        </Splitter>
        <Input label="Email" placeholder="Enter Email" />
        <Input label="Twitter" placeholder="Enter Twitter" />
        <Input label="Discord" placeholder="Enter Discord" />
        <Header
          title="Wallets"
          description="Expedita velit consequatur sit cum sed beatae exercitationem qui."
        />
        <Dropdown
          label="Ethereum"
          placeholder="Enter address"
          options={options}
        />
        <Input label="Solana" placeholder="Enter address" />
        <Input label="Polygon" placeholder="Enter address" />
        <Header
          title="Notifications"
          description="Expedita velit consequatur sit cum sed beatae exercitationem qui."
        />
      </Page>
    </Responsive>
  );
};

export default Settings;
