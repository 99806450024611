import { useState } from "react";
import { useParams } from "react-router-dom";

import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Double from "../../components/_grid/double";
import Information from "../../components/_proposals/information";
import Vote from "../../components/_proposals/vote";
import Profile from "../../components/_profile/profile";
import Grid from "../../components/_grid/grid/index.js";
import Modal from "../../components/_modals/allowUser";
import Task from "../../components/_overview/task";
import Header from "../../components/_layout/header";
import Comments from "../../components/_profile/comments";
import LineChart from "../../components/_charts/line";
import BarChart from "../../components/_charts/bar";
import PieChart from "../../components/_charts/pie";

const Child = () => {
  let { proposal } = useParams();
  const [show, toggleDisplay] = useState(false);

  return (
    <Responsive>
      {show && <Modal onClick={() => toggleDisplay(false)} />}
      <Aside />
      <Page title="Profile">
        <Double>
          <Grid rows="1">
            <Information title="Overview" />
            <Comments />
            <LineChart />
            <BarChart />
            <PieChart />
          </Grid>

          <Grid rows="1">
            <Vote onClick={() => toggleDisplay(true)} data={proposal} />
            <Profile onClick={() => toggleDisplay(true)} />
          </Grid>
        </Double>
      </Page>
    </Responsive>
  );
};

export default Child;

// export const options = {
//   chart: {
//     type: "radialBar",
//   },
//   series: [67],
//   colors: ["#99ccf1"],
//   plotOptions: {
//     radialBar: {
//       startAngle: -135,
//       endAngle: 135,
//       track: {
//         background: "#f4f5f7",
//         startAngle: -135,
//         endAngle: 135,
//       },
//       dataLabels: {
//         name: {
//           show: false,
//         },
//         value: {
//           fontSize: "40px",
//           show: true,
//           fontFamily: `'Secular One', sans-serif`,
//         },
//       },
//     },
//   },
//   fill: {
//     type: "horizontal",
//     gradient: {
//       shade: "dark",
//       type: "horizontal",
//       gradientToColors: ["#f64538", "#fddad7"],
//       stops: [0, 50],
//     },
//   },
//   stroke: {
//     lineCap: "butt",
//   },
//   labels: ["Progress"],
//   series: [
//     {
//       name: "series-1",
//       data: [30, 40, 45, 50, 49, 60, 70, 91],
//     },
//   ],
// };
