import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Container, Route } from "./styles";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <Container>
      {breadcrumbs.slice(1).map(({ breadcrumb }) => (
        <Route key={breadcrumb.key} to={breadcrumb.key}>
          {breadcrumb}
        </Route>
      ))}
    </Container>
  );
};

export default Breadcrumbs;
