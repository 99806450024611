import styled, { useTheme } from "styled-components";

const Svg = styled.svg`
  height: 40px;
  width: 40px;
`;

const Dashboard = () => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M26.8 22H41.2C41.6418 22 42 22.3582 42 22.8V25.2C42 25.6418 41.6418 26 41.2 26H26.8C26.3582 26 26 26.3582 26 26.8V31.2C26 31.6418 25.6418 32 25.2 32H22.8C22.3582 32 22 31.6418 22 31.2V16.8C22 16.3582 22.3582 16 22.8 16H25.2C25.6418 16 26 16.3582 26 16.8V21.2C26 21.6418 26.3582 22 26.8 22Z"
      />
      />
      <path
        fill={theme.icon}
        d="M19.2 6H8C6.89543 6 6 6.89543 6 8V19.2C6 19.6418 6.35817 20 6.8 20H19.2C19.6418 20 20 19.6418 20 19.2V6.8C20 6.35817 19.6418 6 19.2 6ZM41.2 28H28.8C28.3582 28 28 28.3582 28 28.8V41.2C28 41.6418 28.3582 42 28.8 42H40C41.1046 42 42 41.1046 42 40V28.8C42 28.3582 41.6418 28 41.2 28ZM32 32.8V37.2C32 37.6418 32.3582 38 32.8 38H37.2C37.6418 38 38 37.6418 38 37.2V32.8C38 32.3582 37.6418 32 37.2 32H32.8C32.3582 32 32 32.3582 32 32.8ZM10 10.8V15.2C10 15.6418 10.3582 16 10.8 16H15.2C15.6418 16 16 15.6418 16 15.2V10.8C16 10.3582 15.6418 10 15.2 10H10.8C10.3582 10 10 10.3582 10 10.8ZM28.8 6H40C41.1046 6 42 6.89543 42 8V19.2C42 19.6418 41.6418 20 41.2 20H28.8C28.3582 20 28 19.6418 28 19.2V6.8C28 6.35817 28.3582 6 28.8 6ZM19.2 28H6.8C6.35817 28 6 28.3582 6 28.8V40C6 41.1046 6.89543 42 8 42H19.2C19.6418 42 20 41.6418 20 41.2V28.8C20 28.3582 19.6418 28 19.2 28ZM13.2 22C13.6418 22 14 22.3582 14 22.8V25.2C14 25.6418 13.6418 26 13.2 26H6.8C6.35817 26 6 25.6418 6 25.2V22.8C6 22.3582 6.35817 22 6.8 22H13.2ZM19.2 22H16.8C16.3582 22 16 22.3582 16 22.8V25.2C16 25.6418 16.3582 26 16.8 26H19.2C19.6418 26 20 25.6418 20 25.2V22.8C20 22.3582 19.6418 22 19.2 22ZM22.8 6H25.2C25.6418 6 26 6.35817 26 6.8V13.2C26 13.6418 25.6418 14 25.2 14H22.8C22.3582 14 22 13.6418 22 13.2V6.8C22 6.35817 22.3582 6 22.8 6ZM25.2 34H22.8C22.3582 34 22 34.3582 22 34.8V41.2C22 41.6418 22.3582 42 22.8 42H25.2C25.6418 42 26 41.6418 26 41.2V34.8C26 34.3582 25.6418 34 25.2 34Z"
      />
    </Svg>
  );
};

export default Dashboard;
