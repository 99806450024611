import Chart from "react-apexcharts";
// Relative Imports
import { Container } from "./styles";
import { H5 } from "../../../constants/type.js";

const LineChart = () => {
  const series = [
    {
      name: "Guests",
      data: [19, 22, 20, 26],
    },
  ];
  const options = {
    xaxis: {
      categories: ["2019-05-01", "2019-05-02", "2019-05-03", "2019-05-04"],
    },
    curve: "smooth",
  };
  return (
    <Container>
      <H5 mb="20px">Line Chart</H5>
      <Chart options={options} type="line" series={series} />
    </Container>
  );
};

export default LineChart;
