import { useState } from "react";
import Lottie from "lottie-react";
import confetti from "../../../assets/animations/confetti.json";
// https://lottiereact.com/components/Lottie#examples

// Relative Imports
import { Container, Window, Body, Text, Animation, Right } from "./styles";
import { H1, P } from "../../../constants/type.js";

const Modal = ({ onClick }) => {
  const [points, setPoints] = useState("500+");
  const [level, setLevel] = useState("1.2");
  return (
    <Container onClick={onClick}>
      <Window>
        <Body>
          <Text>
            <H1>{points}</H1>
            <P textAlign="center">
              Ohhh yeahhhhh! You earnt {points} XP for completing this task and
              you have been leveled up to level {level}.
            </P>
          </Text>
          <Animation>
            <Lottie
              loop={false}
              animationData={confetti}
              style={{ height: 340 }}
            />
          </Animation>
        </Body>
      </Window>
    </Container>
  );
};

export default Modal;
