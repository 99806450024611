// Relative Imports
import { Container, Button } from "./styles";
import { H5 } from "../../../constants/type.js";
import Cell from "../../cell";

const Profile = ({ onClick }) => {
  return (
    <Container>
      <H5 mb="12px">Accounts</H5>
      <Cell key={"1"} name="Email" value="user@gmail.com" />
      <Cell key={"2"} name="Discord" value="Lorem Ipsum" />
      <Cell key={"3"} name="Twitter" value="Lorem Ipsum" />
      <Cell key={"4"} name="Forum" value="Lorem Ipsum" />
      <Button onClick={onClick}>Contact</Button>
    </Container>
  );
};

export default Profile;
