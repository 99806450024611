import { useState } from "react";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Avatar from "../../components/avatar";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/_modals/avatarModal";
import Tabs from "../../components/tabs";
import Leaderboard from "../../components/_tables/leaderboard";

import { waitlist } from "../../constants/waitlist.js";

const Community = () => {
  const [show, showModal] = useState(false);
  const [avatarData, setAvatarData] = useState(false);

  const renderLeaderboard = () => {
    return <Leaderboard data={waitlist} />;
  };

  return (
    <>
      {show && <Modal avatarData={waitlist} onClick={() => showModal(false)} />}
      <Responsive>
        <Aside />
        <Page title="Community">
          <Grid rows="1">
            <Leaderboard data={waitlist} onClick={() => showModal(true)} />
          </Grid>
        </Page>
      </Responsive>
    </>
  );
};

export default Community;
