// Imports
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { UserTheme } from "./hooks/userTheme.js";
import brand from "./assets/brand/brand.svg";
import { light, dark } from "./constants/themes.js";
// Pages
import Home from "./pages/Home.js";
import Overview from "./pages/Overview/Master.js";
import Waitlist from "./pages/Waitlist/Master.js";
import Profile from "./pages/Waitlist/Child.js";
import Community from "./pages/Community/Master.js";
import Insights from "./pages/Insights/Master.js";
import Support from "./pages/Support/Master.js";
import Settings from "./pages/Settings/Master.js";
import Date from "./pages/Calendar/Master.js";
import Announcements from "./pages/Announcements/Master.js";

function App() {
  const [currentTheme, setTheme] = useState("light");

  return (
    <>
      <UserTheme.Provider value={{ currentTheme, setTheme }}>
        <ThemeProvider theme={currentTheme === "light" ? light : dark}>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/waitlist" element={<Waitlist />} />
              <Route path="/calendar" element={<Date />} />
              <Route path="/waitlist/:id" element={<Profile />} />
              <Route path="/community" element={<Community />} />
              <Route path="/community/:id" element={<Profile />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/support" element={<Support />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/announcements" element={<Announcements />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </UserTheme.Provider>
    </>
  );
}

export default App;
