import { colorGenerator } from "../utils/colors.js";

export const variants = {
  pink: colorGenerator("#E63E98"),
  green: colorGenerator("#40D674"),
  blue: colorGenerator("#017FDD"),
  yellow: colorGenerator("#ECAF01"),
  red: colorGenerator("#F64538"),
  purple: colorGenerator("#9E37A9"),
  black: colorGenerator("#2b2d42"),
  grey: colorGenerator("#8d99ae"),
  white: colorGenerator("#edf2f4"),
  accent: colorGenerator("#EF233C"),
};

export const layout = {
  format: ["px", "rem", "em"],
  fontHeader: `'Secular One', sans-serif`,
  fontBody: `"Inter", sans-serif`,
  shadow: `6px 10px 4px 8px rgba(194, 211, 226, 0.05);`,
  fontSize: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontHeight: [1.0, 1.125, 1.25, 1.375, 1.5],
  fontWeight: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
  media: {
    xxs: 320,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1140,
    xl: 1920,
    xxl: 2560,
  },
  default: {
    radius: "8px",
    spacing: "20px",
    padding: "20px",
    gap: "20px",
    margin: "20px",
  },
};

export const light = {
  name: "light",
  background: `${variants.grey[1].hex}`,
  ground: `${variants.white[1].hex}`,
  foreground: `${variants.grey[0].hex}`,
  navigation: `${variants.grey[0].hex}`,
  hover: `${variants.grey[2].hex}`,
  border: `${variants.grey[2].hex}`,
  primary: `${variants.grey[15].hex}`,
  secondary: `${variants.grey[8].hex}`,
  input: `${variants.grey[0].hex}`,
  placeholder: `${variants.grey[5].hex}`,
  icon: `${variants.grey[11].hex}`,
  layout: layout,
  variants: variants,
};

export const dark = {
  name: "dark",
  background: `${variants.black[16].hex}`,
  ground: `${variants.black[17].hex}`,
  foreground: `${variants.black[18].hex}`,
  navigation: `${variants.black[0].hex}`,
  hover: `${variants.grey[18].hex}`,
  border: `${variants.black[10].hex}`,
  primary: `${variants.black[1].hex}`,
  secondary: `${variants.black[4].hex}`,
  input: `${variants.black[19].hex}`,
  placeholder: `${variants.black[5].hex}`,
  icon: `${variants.black[4].hex}`,
  layout: layout,
  variants: variants,
};
