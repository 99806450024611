// Relative Imports
import { Container, Profile, Image, Row, Column } from "./styles";
import Menu from "../menu/index.js";
import nft from "../../../assets/nft/nft.png";
import { P, H5 } from "../../../constants/type.js";

const Aside = () => {
  return (
    <Container>
      <Profile>
        <Row>
          <Image />
          <Column>
            <H5>Name</H5>
            <P>Last Login: 3:11pm</P>
          </Column>
        </Row>
      </Profile>
      <Menu />
    </Container>
  );
};

export default Aside;
