import styled, { useTheme } from "styled-components";
import media from "../../constants/media.js";

const Svg = styled.svg`
  height: 48px;
  width: 48px;

  ${media.sm`
    margin-right: 0px;
    margin-bottom: 4px;
  `};
`;

const DarkTheme = ({ viewBox, primary, secondary }) => {
  const theme = useTheme();
  return (
    <Svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={theme.secondary}
        d="M10.5963 8.80743C10.2092 9.58154 9.58154 10.2092 8.80743 10.5963L8 11L8.80743 11.4037C9.58154 11.7908 10.2092 12.4185 10.5963 13.1926L11 14L11.4037 13.1926C11.7908 12.4185 12.4185 11.7908 13.1926 11.4037L14 11L13.1926 10.5963C12.4185 10.2092 11.7908 9.58154 11.4037 8.80743L11 8L10.5963 8.80743ZM36.5963 14.8074C36.2092 15.5815 35.5815 16.2092 34.8074 16.5963L34 17L34.8074 17.4037C35.5815 17.7908 36.2092 18.4185 36.5963 19.1926L37 20L37.4037 19.1926C37.7908 18.4185 38.4185 17.7908 39.1926 17.4037L40 17L39.1926 16.5963C38.4185 16.2092 37.7908 15.5815 37.4037 14.8074L37 14L36.5963 14.8074Z"
      />
      <path
        fill={theme.icon}
        d="M37.4928 29.5243C35.4377 37.0054 27.7308 41.4921 20.2789 39.5456C12.8271 37.5992 8.45215 29.9567 10.5072 22.4757C12.0258 16.9476 16.6306 13.0545 21.938 12.1841C22.8567 12.0334 23.7964 11.9733 24.7448 12.0109C25.3679 12.0356 25.8456 12.5612 25.8118 13.1847C25.8011 13.3819 25.7391 13.5735 25.6317 13.7407C25.5846 13.8141 25.5422 13.8839 25.5045 13.95C25.1762 14.5269 24.9139 15.1531 24.7304 15.8211C23.556 20.096 26.056 24.4631 30.3142 25.5753C32.2644 26.0848 34.2452 25.8231 35.9323 24.9791C36.0824 24.904 36.2509 24.8068 36.4379 24.6874L36.4379 24.6874C36.9157 24.3824 37.5447 24.5188 37.8428 24.9922C37.9401 25.1468 37.9935 25.3255 37.9972 25.5093C38.0172 26.5032 37.9306 27.5134 37.7292 28.5255C37.6629 28.8584 37.5842 29.1915 37.4928 29.5243ZM29.251 29.4455C23.4836 27.9391 19.8665 22.4519 20.5155 16.6804C17.6109 17.8051 15.2494 20.2533 14.3623 23.4826C12.8944 28.8262 16.0193 34.2851 21.3421 35.6754C26.224 36.9506 31.2592 34.3603 33.1923 29.7996C31.899 29.8995 30.5703 29.7901 29.251 29.4455Z"
      />
    </Svg>
  );
};

export default DarkTheme;
