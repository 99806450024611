import styled from "styled-components";

export const Container = styled.div`
  grid-gap: 20px;
  border: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.foreground};
  padding: 20px 20px 0px 20px;
  border-radius: ${(props) => props.theme.layout.default.radius};
`;

export const Thread = styled.div`
  display: flex;
  height: 100px;
  margin-top: 20px;
  grid-gap: 20px;
`;

export const Avatar = styled.div`
  height: 48px;
  aspect-ratio: 1 / 1;
  border-radius: ${(props) => props.theme.layout.default.radius};
  background: ${(props) => props.theme.background};
`;

export const Comment = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
