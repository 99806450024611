// Relative Imports
import { Container, Column, Route, Disabled } from "./styles";
import { H2, P } from "../../../constants/type.js";

const Level = ({ route, title, description, disabled }) => {
  return (
    <Container>
      <>
        <Route to={route}>
          <Column>
            <H2 color="primary" mb="8px">
              {title}
            </H2>
            <P>{description}</P>
          </Column>
        </Route>
      </>
    </Container>
  );
};

export default Level;
