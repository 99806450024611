import Chart from "react-apexcharts";
// Relative Imports
import { Container } from "./styles";
import { H5 } from "../../../constants/type.js";

const BarChart = () => {
  const options = { labels: ["Comedy", "Action", "Romance", "Drama", "SciFi"] };
  const series = [4, 5, 6, 1, 5]; //our data
  return (
    <Container>
      <H5 mb="20px">Bar Chart</H5>
      <Chart options={options} type="pie" series={series} />
    </Container>
  );
};

export default BarChart;
