import styled, { keyframes } from "styled-components";
import media from "../../../constants/media.js";

const scale = keyframes`
  0% { -webkit-transform: translateY(-20px)  }
  50% { -webkit-transform: translateY(20px)   }
  100% { -webkit-transform: translateY(0px)  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 10000;
  position: fixed;
  display: flex;
  justify-content: center;
  padding-top: 80px;
`;

export const Window = styled.div`
  width: 65%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${scale} 500ms ease-out;
  background: ${(props) => props.theme.foreground};
  margin: ${(props) => props.theme.layout.default.margin};
  border-radius: ${(props) => props.theme.layout.default.radius};
  padding: ${(props) => props.theme.layout.default.padding};

  ${media.sm`
    max-height: 700px;
  `};
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  grid-gap: 8px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 12px 0px;
`;

export const Image = styled.div`
  height: 460px;
  width: 460px;
  border-radius: ${(props) => props.theme.layout.default.radius};
  background: ${(props) => props.theme.placeholder};
`;

export const Data = styled.div`
  width: 50%;
  padding-left: ${(props) => props.theme.layout.default.padding};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.layout.default.gap};
  padding-top: ${(props) => props.theme.layout.default.padding};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
`;

export const Button = styled.button`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  font-size: 16px;
  transition: 750ms;
  background: ${(props) => props.theme.variants.grey[10].hex};
  border-radius: ${(props) => props.theme.layout.default.radius};
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};

  &:hover {
    cursor: pointer;
    transition: 750ms;
    background: ${(props) => props.theme.variants.accent[11].hex};
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.6);
  }
`;
