import { useState, useMemo } from "react";
import { Calendar, Views, DateLocalizer } from "react-big-calendar";
import moment from "moment";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/_modals/redeemModal";
import Header from "../../components/_layout/header";
import Task from "../../components/_overview/task";

// let allViews = Object.keys(Views).map((k) => Views[k]);

const Date = () => {
  // const { defaultDate, max } = useMemo(
  //   () => ({
  //     defaultDate: new Date(2015, 3, 13),
  //     max: dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours"),
  //   }),
  //   []
  // );
  return (
    <Responsive>
      <Aside />
      <Page title="Calendar">
        {/*
          <Calendar
            backgroundEvents={backgroundEvents}
            dayLayoutAlgorithm="no-overlap"
            defaultDate={defaultDate}
            defaultView={Views.DAY}
            events={events}
            localizer={localizer}
            max={max}
            showMultiDayTimes
            step={60}
            views={allViews}
          />
        */}
      </Page>
    </Responsive>
  );
};

export default Date;
