// Relative Imports
import {
  Container,
  Window,
  Row,
  Button,
  Column,
  Body,
  Image,
  Data,
  Header,
  Grid,
} from "./styles";
import { Title, H6, P } from "../../../constants/type.js";

const Modal = ({ onClick }) => {
  return (
    <Container>
      <Window>
        <Body>
          <Row>
            <Image />
            <Data>
              <div>
                <Header>
                  <Title
                    color={"primary"}
                    fontWeight={"bold"}
                    fontSize="32px"
                    textTransform="uppercase"
                  >
                    NFT #3212
                  </Title>
                  <P>@Username</P>
                </Header>
                <Grid>
                  <Column>
                    <H6>Level</H6>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H6>Rank</H6>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H6>Status</H6>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H6>Points</H6>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H6>Access</H6>
                    <P>Placeholder</P>
                  </Column>
                </Grid>
              </div>
              <Button onClick={onClick}>Close</Button>
            </Data>
          </Row>
        </Body>
      </Window>
    </Container>
  );
};

export default Modal;
