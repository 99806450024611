// Library Imports
import React from "react";

// Relative Imports
import { Container, Checkboxes, Icon, Hide, Show, Label, Link } from "./styles";

const Radio = ({ className, text, checked, ...props }) => (
  <Container>
    <Label>I approve this user</Label>
    <Checkboxes>
      <Hide checked={checked} {...props} />
      <Show checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Show>
    </Checkboxes>
  </Container>
);

export default Radio;
